<template>
  <div class="user-agreement ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>用户协议</div>
    <div class="ly-center">
      <h1>重庆云昇新控智能科技有限公司智慧水务软件许可及服务协议</h1>
      <h2>【首部及导言】</h2>
      <p>欢迎您使用重庆云昇新控智能科技有限公司智慧水务软件及服务！</p>
      <p>
        为使用重庆云昇新控智能科技有限公司智慧水务软件（以下统称“本软件”）及服务，您应当阅读并遵守《重庆云昇新控智能科技有限公司智慧水务软件许可及服务协议》（以下简称“本协议”），以及专项规则等。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示您注意。</p>
      <p>除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及相关服务。您的下载、安装、使用、登录等行为即视为您已阅读并同意本协议的约束。</p>
      <p>如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
      <h2>一、【协议的范围】</h2>
      <h3>1.1【协议适用主体范围】</h3>
      <p>本协议是用户（以下可称为“您”）与重庆云昇新控智能科技有限公司之间关于下载、安装、使用、登录本软件，以及使用本服务所订立的协议。</p>

      <h3>1.2【协议关系及冲突条款</h3>
      <p>
        本协议被视为《重庆云昇新控智能科技有限公司服务协议》及《重庆云昇新控智能科技有限公司智慧水务软件许可及服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。本协议内容同时包括重庆云昇新控智能科技有限公司可能不断发布的关于本服务的相
        关协议、服务声明、业务规则及公告指引等内容（以下统称为“专项规则”）。专项规则一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。</p>
      <h2>二、【关于本服务】</h2>
      <h3>2.1【相关定义】</h3>
      <p>
        （1）重庆云昇新控智能科技有限公司智慧水务软件：指由重庆云昇新控智能科技有限公司开发、运营并享有独立知识产权的，专为市场营销、在线客服、销售管理等需求提供内容制作、内容分发、用户数据分析、用户互动等服务的企业服务软件，以下可简称为“智慧水务”。</p>
      <p>（2）单位用户：指按照重庆云昇新控智能科技有限公司指定方式、渠道获得本软件使用权，并对相应的智慧水务帐号（以下可简称为“帐号”，包括单位用户帐号和个人用户帐号）有管理权限的用户。</p>
      <p>（3）个人用户：指经过单位用户授权使用本软件的普通用户。单位用户仅可以将本软件的使用权转授权给属于本单位的工作人员或其他协助运营智慧水务账户的人员进行使用，这里称为个人用户。</p>

      <h3>2.2【本服务内容】</h3>
      <p>本服务内容是指，通过本软件可以对重庆云昇新控智能科技有限公司指定产品进行相关操作，具体产品以重庆云昇新控智能科技有限公司实际指定的为准。用户在使用本服务进行相关操作时，需要遵守相关产品的服务规则、专项规则等。</p>

      <h3>2.3【本服务形式】</h3>
      <p>
        您可能可以通过电脑、移动设备等终端以客户端、网页等形式使用本服务，具体以重庆云昇新控智能科技有限公司提供的为准，同时，重庆云昇新控智能科技有限公司会不断丰富您使用本服务的终端、形式等。当您使用本服务时，您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本服务。由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担。</p>
      <h3>2.4【许可的范围】</h3>
      <p>2.4.1 重庆云昇新控智能科技有限公司给予您一项非排他性的许可，以使用本软件。</p>
      <p>2.4.2 您可以制作本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。</p>
      <p>2.4.3
        本条及本协议其他条款未明示授权的其他一切权利仍由重庆云昇新控智能科技有限公司保留，您在行使这些权利时须另外取得重庆云昇新控智能科技有限公司的书面许可。重庆云昇新控智能科技有限公司如果未行使前述任何权利，并不构成对该权利的放弃。</p>
      <h3>2.5【接受本服务的形式】</h3>
      <p>2.5.1
        本服务是有偿使用的服务，单位用户可以通过重庆云昇新控智能科技有限公司指定方式、渠道购买获取本服务及相应重庆云昇新控智能科技有限公司智慧水务号码的使用权，单位用户支付本服务的相关服务费、按照重庆云昇新控智能科技有限公司要求提交相关资质材料以及满足其他重庆云昇新控智能科技有限公司的要求后，重庆云昇新控智能科技有限公司会分配相应的重庆云昇新控智能科技有限公司智慧水务号码给单位用户，单位用户可在相应使用期限内，使用本服务及相应重庆云昇新控智能科技有限公司智慧水务号码。
        个人用户应在单位用户的授权范围内使用本服务及相应重庆云昇新控智能科技有限公司智慧水务号码。
        重庆云昇新控智能科技有限公司保留未来对本服务改变和说明的权利。您通过重庆云昇新控智能科技有限公司智慧水务帐号使用重庆云昇新控智能科技有限公司的其他服务时，须同时遵守各项服务的服务条款。</p>
      <p>2.5.2
        重庆云昇新控智能科技有限公司将根据市场与技术的发展，向您提供与本软件相关的各种互联网以及移动通信增值服务，包括免费和收费的增值服务。重庆云昇新控智能科技有限公司保留单方对相关服务收取费用及改变收费标准、方式的权利；如相关服务由免费变更为收费的，重庆云昇新控智能科技有限公司将以适当的形式通知，您可自主选择接受或拒绝该收费的服务，如您拒绝该收费的服务的，您将无法使用该服务。如您接受该收费的服务，您应当保证在使用该收费服务时，将按照重庆云昇新控智能科技有限公司相关收费规定支付费用，如拒付或拖欠费用，重庆云昇新控智能科技有限公司有权停止服务，并依法追偿损失及赔偿。
        根据市场情况变化，产品开发及运营成本增加等因素，重庆云昇新控智能科技有限公司有权自行调整相关产品及服务的市场价格。如您不接受调整后的价格，您将在已购买产品或服务有效期截止后不能继续使用该产品和服务。</p>
      <p><em>2.5.3
        您应该在本服务到期前，提前联系重庆云昇新控智能科技有限公司的指定推广商或重庆云昇新控智能科技有限公司认可的其他方式办理完成续约手续，否则，重庆云昇新控智能科技有限公司有权在您重庆云昇新控智能科技有限公司智慧水务的使用期限到期后，在不通知您的情况下收回、终止本服务以及相应重庆云昇新控智能科技有限公司智慧水务号码的使用权，重庆云昇新控智能科技有限公司收回之后，您不可以再使用本服务，同时，重庆云昇新控智能科技有限公司有权随时清空您因使用本服务产生的全部数据、信息等，包括但不限于群发信息、员工信息、客户或好友信息和聊天记录等。若由此，给您造成任何损失的，您应自行承担全部损失，并无权向重庆云昇新控智能科技有限公司提出任何主张。</em>
        回收后，您若还需使用本服务，需重新支付费用，重庆云昇新控智能科技有限公司会重新分配新的重庆云昇新控智能科技有限公司智慧水务号码，您将不能继续使用原有重庆云昇新控智能科技有限公司智慧水务号码和相应数据。</p>
      <p>2.5.4
        您申请开通本服务时应先向重庆云昇新控智能科技有限公司提交申请企业的经营或运营所必须的相关合法、真实的资质文件等资料供重庆云昇新控智能科技有限公司公司核查申请企业信息的真实性和合法性。（包括但不限于营业执照等基本主体资料、特殊行业经营或运营所必须的特殊行业的相关特殊资质或审批文件等特殊主体资料）。
        若相关资料有变更的，您必须及时向重庆云昇新控智能科技有限公司提供最新的相关资料。
        如重庆云昇新控智能科技有限公司公司在您开通和使用本产品及服务的过程中发现您不具备相关资质或您提交的资料（包括但不限于主体的身份信息、行业资质、特殊行业经营或运营所必须的特殊行业的相关特殊资质或审批文件等）存在伪造或冒用其它企业的情形，，重庆云昇新控智能科技有限公司公司有权立即终止已开通产品的全部服务并不退还您已经支付的费用。若由此给您造成任何损失的，您应自行承担全部损失，并无权向重庆云昇新控智能科技有限公司提出任何主张。</p>
      <p>2.5.5
        您同意重庆云昇新控智能科技有限公司公司有权通过第三方合作机构对您提交的审核资料进行核实查证并向您收取相应的审核费用。如您拒绝支付审核费用，您的申请资料将无法获得审核，由此重庆云昇新控智能科技有限公司公司将会拒绝或取消您对本产品及服务的购买需求且重庆云昇新控智能科技有限公司公司不因此而承担任何责任。如您提供的资料因各种原因（包括但不限于资料不全、资料信息显示不全或不能清晰分辨、资质虚假嫌疑、已有付费产品违规历史记录等）不能通过审核，重庆云昇新控智能科技有限公司公司将不会退回相应的审核费用。若由此给您造成任何损失的，您应自行承担全部损失，重庆云昇新控智能科技有限公司不因此而承担任何责任。</p>
      <p>2.5.6
        您应通过重庆云昇新控智能科技有限公司官方指定的销售渠道购买本产品及相应服务。如您通过非官方渠道购买本产品，您的合法权益可能将无法得到保证，由此可能引起的账号封停、高价购买、信息泄露、无相应售后服务等情况，重庆云昇新控智能科技有限公司将不承担任何责任。</p>
      <h2>三、【帐号注册】</h2>
      <p>3.1
        您应当通过登录平台网站或重庆云昇新控智能科技有限公司其他指定途径，使用手机号码注册智慧水务帐号。您注册后获得智慧水务帐号使用权。帐号一经注册成功，未经重庆云昇新控智能科技有限公司书面许可，相应的手机号码不得变更，且该帐号不可转让、不可赠与、不可继承等。</p>
      <p>3.2 用户注册帐号时，应使用用户拥有合法使用权的手机号码，并遵守《智慧水务号码规则》及相关协议、规则等的约束。</p>
      <p>3.3
        用户不得违反本协议约定将帐号用于本协议以外的其他目的。否则，重庆云昇新控智能科技有限公司有权随时单方限制、中止或终止向用户提供本服务，且未经重庆云昇新控智能科技有限公司同意用户不得再次使用本服务，由此给您造成的损失（包括但不限于您因使用本服务而产生的全部数据、信息等被清空、丢失等），您应自行承担。</p>
      <p>3.4
        用户注册帐号使用的手机号码，作为用户登录及使用本服务的凭证。用户应当做好手机号码、密码，以及进入和管理本服务中的各类产品与服务的口令、密码等的保密措施。因用户保密措施不当或其他行为，致使上述口令、密码等丢失或泄漏所引起的一切损失和后果，均由用户自行承担。</p>
      <p>3.5
        用户可以根据自己的需求，在智慧水务帐号内添加本服务帐号下的协作者，进行用户指定的操作。用户可添加的协作者的数量具体以由重庆云昇新控智能科技有限公司最终确定并实际提供的为准。用户应对添加的协作者的相关操作等使用本服务的行为，承担保证义务，保证协作者应在用户的授权范围内使用本服务及相应帐号并遵守本协议的约定。如协作者违反本协议约定的，用户应对因此引发的全部法律后果承担连带赔偿责任。</p>
      <p>3.6
        用户保证：您注册本服务账户的手机号码，以及您添加的本服务账号的协作者使用的手机号码均是合法的。前述全部手机号码在本服务中进行的包括但不限于以下事项：注册本服务帐户、提交相应资质材料、确认和同意相关协议和规则、选择具体服务类别以及进行费用结算等事项，均是您自行或您授权他人进行的行为，对您均有约束力。同时，您承担以前述全部手机号码为标识进行的全部行为的法律责任。</p>
      <p>3.7
        若用户发现有他人冒用或盗用其帐号及密码、或任何其他未经用户合法授权的情形时，应立即以有效方式通知重庆云昇新控智能科技有限公司（包括但不限于提供用户的身份信息和相关身份资料、相关事实情况及用户要求等）。重庆云昇新控智能科技有限公司收到用户的有效请求并核实身份后，会根据不同情况采取相应措施。若用户提供的信息不完全，导致重庆云昇新控智能科技有限公司无法核实用户的身份或无法判断用户的需求等，而导致重庆云昇新控智能科技有限公司无法进行及时处理，给用户带来损失的，用户应自行承担。同时，重庆云昇新控智能科技有限公司对用户的请求采取措施需要合理期限，对于用户通知重庆云昇新控智能科技有限公司以及重庆云昇新控智能科技有限公司根据用户的有效通知采取措施之前，由于他人行为给用户造成的损失，重庆云昇新控智能科技有限公司不承担任何责任。</p>
      <h2>四、【企业产品认证服务】</h2>
      <p>4.1 重庆云昇新控智能科技有限公司企业产品认证是指重庆云昇新控智能科技有限公司或重庆云昇新控智能科技有限公司授权的第三方审核机构对
        “重庆云昇新控智能科技有限公司智慧水务”的账号运营主体所提交的主体信息（包括但不限于营业执照，法人身份证、运营人身份证或其他法定的相关帐号主体资质证明文件）进行核对的服务。</p>
      <p>4.2
        请理解，重庆云昇新控智能科技有限公司企业产品认证服务仅限于对企业产品的账号运营主体提交的资料及信息进行合理、谨慎的形式审查，但在重庆云昇新控智能科技有限公司的合法权限和合理能力范围内，重庆云昇新控智能科技有限公司无法实质审查企业产品的账号运营主体提交的资料和信息以及企业产品的账号运营主体的实际经营、运营、推广等行为，并无法确保其实质的真实性、合法性。通过了重庆云昇新控智能科技有限公司企业产品认证并不代表重庆云昇新控智能科技有限公司对该主体合法性、真实性等做出任何担保、承诺、保证等。所有企业产品的账号运营主体均自行、独立对其账户的任何行为、行动、言论、信息内容等承担相关法律责任。企业产品的账号运营主体与其他用户或第三方发生争议的，由企业产品的账号运营主体独立对外承担责任，若因此给重庆云昇新控智能科技有限公司、其他用户或第三方造成损害的，企业产品的账号运营主体应当依法独立承担责任并予以赔偿。如果您的企业／机构也需要申请重庆云昇新控智能科技有限公司认证手机。</p>

      <h2>五、【软件的获取】</h2>
      <p>5.1 您可以直接从重庆云昇新控智能科技有限公司的网站上获取本软件，也可以从得到重庆云昇新控智能科技有限公司授权的第三方获取。</p>
      <p><em>5.2 如果您从未经重庆云昇新控智能科技有限公司授权的第三方获取本软件或与本软件名称相同的安装程序，重庆云昇新控智能科技有限公司无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。</em></p>
      <h2>六、【软件的安装与卸载】</h2>
      <p><em>6.1 重庆云昇新控智能科技有限公司可能为不同的终端、系统等开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。</em></p>
      <p>6.2 下载安装程序后，您需要按照该程序提示的步骤正确安装。 </p>
      <p>6.3 为提供更加优质、安全的服务，在本软件安装时重庆云昇新控智能科技有限公司可能推荐您安装其他软件，您可以选择安装或不安装。</p>
      <p>6.4 如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助重庆云昇新控智能科技有限公司改进产品服务，请告知卸载的原因。</p>

      <h2>七、【软件的更新】</h2>
      <p>7.1 为了增进用户体验、完善服务内容，重庆云昇新控智能科技有限公司将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</p>
      <p><em>7.2 本软件可能为您默认开启自动升级、更新等功能，您可以在软件设置中自行选择是否需要开启此功能。</em></p>
      <p>7.3 为了改善用户体验，并保证服务的安全性和功能的一致性，重庆云昇新控智能科技有限公司有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</p>
      <p>7.4 本软件新版本发布后，旧版本的软件可能无法使用。重庆云昇新控智能科技有限公司不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。</p>

      <h2>八、【用户个人信息保护】</h2>
      <p>8.1
        保护用户个人信息是重庆云昇新控智能科技有限公司的一项基本原则，重庆云昇新控智能科技有限公司将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可重庆云昇新控智能科技有限公司不会向第三方公开、透露用户个人信息。在您使用本产品的过程中，我们可能会收集您在使用服务时提供或因为使用服务而产生的信息，目的是为了向您提供服务、优化我们的服务以及保障您的账号安全等。重庆云昇新控智能科技有限公司对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。</p>
      <p>8.2 您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规或政策有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。</p>
      <p>8.3 一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>
      <p>8.4 重庆云昇新控智能科技有限公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
      <p>8.5
        智慧水务用户应当建立信息安全保护机制并对最终用户进行信息安全教育，包括但不限于：将管理权限交予适当的人选；合理设定相关人员的管理权限；提醒或要求最终用户注意信息安全和账号安全，防止账号被他人盗用而导致的信息泄露；妥善保管注册账号及密码的安全，用户需要对自己账号的使用行为承担法律责任；聊天过程中涉及敏感信息或者财务资金时，请务必通过其他途径确认对方的合法身份。</p>
      <p>8.6 重庆云昇新控智能科技有限公司非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用本服务前，应事先取得您家长或法定监护人的书面同意。</p>
      <p>8.7
        您应对通过本服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重。您不应搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息。对于违反本条约定而产生的一切后果由您自行承担。</p>

      <h2>九、【用户行为规范】</h2>
      <h3>9.1【用户注意事项】</h3>
      <p>9.1.1 您充分理解并同意：某些特定服务可能还需同意单独的协议、规则等，您在使用该项服务前请仔细阅读前述相关协议、规则。</p>
      <p>9.1.2 重庆云昇新控智能科技有限公司有权在本服务中或通过本服务向您展现各种信息，包括但不限于广告信息、新闻信息及宣传信息等，该信息可能以系统消息或弹出窗口等形式出现。</p>
      <p>9.1.3 您可以选择不向重庆云昇新控智能科技有限公司提供您的某些信息，或者根据产品设置取消重庆云昇新控智能科技有限公司收集某些信息，但因此可能会导致相关服务功能无法实现。</p>
      <p>9.1.4 如果您停止使用本软件及服务或服务被终止或取消，重庆云昇新控智能科技有限公司可以从服务器上永久地删除您的数据。您的服务停止、终止或取消后，重庆云昇新控智能科技有限公司无法、也没有义务向您返还任何数据。</p>
      <p>9.1.5
        您理解并同意：本软件是重庆云昇新控智能科技有限公司专门为方便各公司、企业等单位用户或经单位用户授权使用的个人用户，基于相应单位的业务运营等目的，而进行内部或外部信息交流、沟通的工具，因此，单位用户可能会基于内部信息安全管理、业务运营等目的，接触到因您使用本服务产生的全部数据、信息等（包括但不限于发送信息、员工信息、客户或好友信息等），并对其进行查询、管理等操作。若您需要与他人进行与您所在单位中的本职工作无关的信息交流、沟通，请您使用您的私人通讯工具，以免您的个人信息泄露。</p>

      <h3>【用户禁止行为】</h3>
      <p>您在使用本服务或本软件的过程中，应遵守相关法规政策、用户协议、规则规范等，不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：</p>
      <p>9.2.1 法律法规禁止行为</p>
      <p>不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：</p>
      <p>（1）反对宪法所确定的基本原则的；</p>
      <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3）损害国家荣誉和利益的；</p>
      <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p>（8）宣传贩卖军火、毒品、违禁药品的；</p>
      <p>（9）侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（10）传播代孕、售卖个人信息、代开发票、办证刻章等不当行为的；</p>
      <p>（11）含有法律、行政法规禁止的其他行为或内容的。</p>
      <p>9.2.2 软件不当使用</p>
      <p>除非法律允许或重庆云昇新控智能科技有限公司书面许可，您不得从事下列行为：</p>
      <p>（1）删除本软件及其副本上关于著作权的信息；</p>
      <p>（2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</p>
      <p>（3）对重庆云昇新控智能科技有限公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
      <p>
        （4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非重庆云昇新控智能科技有限公司经授权的第三方工具/服务接入本软件和相关系统；</p>
      <p>（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
      <p>（6）通过非重庆云昇新控智能科技有限公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件及服务，或制作、发布、传播上述工具；</p>
      <p>（7）自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰；</p>
      <p>（8）其他可能影响、干扰软件正常运行的行为。</p>
      <p>9.2.3 危害平台健康和影响用户体验</p>
      <p>基于本软件或服务目的是为了您单位的管理、业务运营等目的而进行单位内部员工沟通、管理及对外商务沟通、管理，所以您不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：</p>
      <p>（1）进行任何超出本软件或服务设定目的好友或用户之间的非正常沟通、交流等目的的行为；</p>
      <p>
        （2）出于超出本软件或服务设定目的好友或用户之间非正常沟通、交流等目的（包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），通过自己添加或诱导他人添加等任何方式使自己或其他第三方账号与其他用户形成好友关系（好友关系包括但不限于单向好友关系和双向好友关系，下同）【您理解并同意：若您违反前述约定、本协议或相关法规政策，重庆云昇新控智能科技有限公司有权不经您同意而直接解除您的好友关系；若您的单向或双向好友(简称“违规用户”)违反前述约定、本协议或相关法规政策，重庆云昇新控智能科技有限公司有权按照本协议、相关协议或相关法规政策等，对违规用户进行处理，由此可能影响您与违规用户之间的信息交流、好友关系等以及您对本服务的使用，同时您知悉：这是重庆云昇新控智能科技有限公司为了维护健康良好的网络环境而采取的必要措施，若由于重庆云昇新控智能科技有限公司按照前述约定对违规用户采取措施而对您产生影响或任何损失的，您同意不追究重庆云昇新控智能科技有限公司的任何责任或不向重庆云昇新控智能科技有限公司主张任何权利。】</p>
      <p>（3）通过非人工的手动方式（比如外挂软件、程序等）发送信息。</p>
      <p>（4）发送各种形式的广告、推广、垃圾、骚扰或违法违规等信息。</p>
      <p>（5）通过非人工的手动方式（比如外挂软件、程序等）进行账号登录、加好友、加群组、拉手机账号入群或讨论组、点赞、留言、发帖、发相片等非正常的操作行为。</p>
      <p>（6）通过本服务向好友或其他用户发送大量非正常信息；</p>
      <p>（7）其他可能影响平台健康、用户交流、用户体验的行为。</p>
      <p>9.2.4 危害平台安全内容</p>
      <p>（1）以虚假身份或冒用他人身份误导、欺骗其他用户；</p>
      <p>（2）传播虚假中奖信息、钓鱼欺诈信息、非法或虚假理财信息等非法、诈骗信息，可能诱使用户上当受骗蒙受损失。</p>
      <p>（3）传播包含病毒、木马等的文件、程序或相应制作教程等的内容、信息。</p>
      <p>9.2.5 数据获取使用</p>
      <p>为了保护用户数据安全， 您不得从事包括但不限于以下行为或传播相关信息或组织相关活动(也不得为其提供便利)：</p>
      <p>（1）未经其他手机用户明确同意，或未向其他手机用户如实披露数据用途、使用范围等相关信息的情形下复制、存储、使用或传输其他手机用户数据的，侵害其它手机用户合法权益的。</p>


      <p>（2）将其他手机用户手机号、昵称、手机号、电子邮箱地址和出生日期等个人隐私信息用于任何未经用户及手机平台授权的用途的。</p>
      <p>（3）企图进行反射查找、跟踪、关联、挖掘、获取用户手机号、手机号、电子邮箱地址和出生日期等个人隐私信息的。</p>
      <p>（4）通过各种程序、软件等抓取任何用户信息或与本服务、本软件相关的任何信息、数据。</p>
      <p>9.2.6 其他禁止行为</p>
      <p>
        （1）您使用本软件或服务，应在从事合法的单位内部员工沟通、管理及对外商务沟通、管理的范围之内，不得从事任何您单位无权开展的任何业务、商务活动等，包括但不限于通过本软件发送超出您单位经营范围之外的任何信息或从事任何超出您单位经营范围之外的行为等。</p>
      <p>
        （2）在未经重庆云昇新控智能科技有限公司书面授权的情况下，您不应以重庆云昇新控智能科技有限公司、重庆云昇新控智能科技有限公司代理商、重庆云昇新控智能科技有限公司合作伙伴、经重庆云昇新控智能科技有限公司官方认证等名义进行对外沟通或从事相应的行为。</p>
      <p>（3）未经重庆云昇新控智能科技有限公司许可您不得从事销售Q币行为，也不得从事销售或买卖手机号或其他重庆云昇新控智能科技有限公司产品或服务的行为。</p>
      <p>（4）您不应将本产品的使用权向他人出借、出租、转让、转售等。</p>
      <p>（5）其他未经重庆云昇新控智能科技有限公司明示授权、许可或违反本协议及相关协议、规则的行为。</p>

      <h3>9.3【对自己行为负责】</h3>
      <p><em>您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。重庆云昇新控智能科技有限公司无法且不会对您因前述风险而导致的任何损失或损害承担责任。</em>
      </p>

      <h3>9.4 【违约处理】</h3>
      <p>
        您理解并同意：为维护互联网安全及秩序，保护网络用户合法权益，若重庆云昇新控智能科技有限公司发现或收到他人举报您有违反相关约定、本协议或相关法规政策等，重庆云昇新控智能科技有限公司有权根据自己的判断，随时单方根据相应情形采取以下一项或多项措施（具体措施的时间长短由重庆云昇新控智能科技有限公司根据您的违法违约情节相应情况确定），由此导致或产生的任何损失或第三方主张的任何索赔、要求等，由您自行承担：</p>


      <p>（1） 对您进行警告。</p>
      <p>（2）2 限制、暂停、终止使用本软件或本服务部分或全部功能（包括但不限于限制添加好友功能、发送添加好友请求等）。</p>
      <p>（3） 删除、屏蔽相关内容或断开链接。</p>
      <p>（4） 中止、终止您对相应重庆云昇新控智能科技有限公司智慧水务号码的使用(简称“封号”) 。</p>
      <p>（5） 解除您的好友关系。</p>
      <p>（6）
        如您受到有权机关调查或被第三方投诉，或您投诉第三方，重庆云昇新控智能科技有限公司有权将争议中相关方的主体资料、联系方式、投诉相关内容等必要信息（包括但不限于名称、电话、证照等）提供给相关方或主管部门，以便及时解决投诉纠纷，保护各方合法权益。</p>
      <p>（7）依法追究您的法律责任。</p>

      <h3>9.5 【对损害的处理】</h3>
      <p><em>您违反约定，重庆云昇新控智能科技有限公司依约采取相应措施，导致您或任何第三方损害的，您应当自行承担相应后果（包括但不限于重庆云昇新控智能科技有限公司依约采取的措施致使您已支付费用对应的服务受到影响，您同意重庆云昇新控智能科技有限公司对该部分已支付的费用无需退还并不承担任何责任，您应自行承担相关损失。）；重庆云昇新控智能科技有限公司因此遭受损失的，您也应当一并赔偿。</em>
      </p>

      <h3>9.6 【对账号封停处理的申诉】</h3>
      <p><em>您如果对您的账号被重庆云昇新控智能科技有限公司公司封号有异议的，可以通过申诉流程向重庆云昇新控智能科技有限公司公司提出申诉。</em></p>


      <h2>十、【第三方提供的产品或服务】</h2>
      <p>您在重庆云昇新控智能科技有限公司平台上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议、规则、规范等。腾 讯和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</p>
      <p>
        您使用本软件或要求重庆云昇新控智能科技有限公司提供特定服务时，本软件可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供（包括但不限于您通过本服务跳转到的第三方提供的服务及内容，第三方通过重庆云昇新控智能科技有限公司开放平台接入的服务及内容等），重庆云昇新控智能科技有限公司无法保证第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，您应自行承担相关风险、后果。</p>
      <h2>十一、【知识产权声明】</h2>
      <p> 11.1
        重庆云昇新控智能科技有限公司是本软件的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，重庆云昇新控智能科技有限公司依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。</p>
      <p> 11.2 未经重庆云昇新控智能科技有限公司或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</p>


      <h2>十二、【您授予的许可使用权】</h2>
      <p></p>您完全理解并同意不可撤销地授予重庆云昇新控智能科技有限公司及其关联公司下列权利：
      <p>12.1 重庆云昇新控智能科技有限公司在本软件中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归重庆云昇新控智能科技有限公司所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。
      <p>12.2 对于您提供的资13料及您使用本产品所涉及的使用场景（包括但不限于网页、软件或其他布置与本软件功能相关联的线上或线下承载平台），<em>您授予重庆云昇新控智能科技有限公司及其关联公司全球通用的、永久的、免费的许可使用权利(并有权在多个层面对该权利进行再授权)</em>，使重庆云昇新控智能科技有限公司公司有权(全部或部份地)使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。
      <p>12.3 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经重庆云昇新控智能科技有限公司、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
      <p>12.4
        当您违反本协议或与重庆云昇新控智能科技有限公司签订的其他协议的约定，重庆云昇新控智能科技有限公司有权以任何方式通知产品部门（企业手机、营销手机、重庆云昇新控智能科技有限公司智慧水务），要求其对您的权益采取限制措施，包括但不限于终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。
      <h2>十三、【终端安全责任】</h2>
      <p><em>13.1
        您理解并同意，本软件或本服务同大多数互联网软件、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；您下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您终端的信息和数据的安全，继而影响本软件、本服务的正常使用等）。因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。</em>
      </p>
      <p>13.2 您不得制作、发布、使用、传播用于窃取智慧水务帐号及他人个人信息、财产的恶意程序。</p>
      <p><em>13.3
        维护软件安全与正常使用是重庆云昇新控智能科技有限公司和您的共同责任，重庆云昇新控智能科技有限公司将按照行业标准合理审慎地采取必要技术措施保护您的终端信息和数据安全，但是您承认和同意重庆云昇新控智能科技有限公司不能就此提供任何保证。</em>
      </p>
      <p><em>13.4 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意重庆云昇新控智能科技有限公司有关防范诈骗犯罪的提示。</em></p>
      <h2>十四、【第三方软件或技术】</h2>
      <p>14.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。</p>
      <p>14.2
        本软件如果使用了第三方的软件或技术，重庆云昇新控智能科技有限公司将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。<em>如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求重庆云昇新控智能科技有限公司给予协助，您应当自行承担法律责任。</em>
      </p>
      <h2>十五、【其他】</h2>
      <p><em>15.1
        您使用本软件或本服务即视为您已阅读并同意受本协议的约束。重庆云昇新控智能科技有限公司有权在必要时修改本协议条款。您可以在本软件、本服务的最新版本中查阅相关协议条款。本协议条款变更后，如果您继续使用本软件、本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本软件。</em>
      </p>
      <p><em>15.2 本协议签订地为中华人民共和国重庆市渝北区。</em></p>
      <p><em>15.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</em></p>
      <p><em>15.4 若您和重庆云昇新控智能科技有限公司之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</em></p>
      <p>15.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
      <p>15.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
      <p>15.7 若本协议有中文、英文等多个语言版本，相应内容不一致的，均以中文版的内容为准。</p>

    </div>
  </div>
</template>
<script>

export default {
  name: 'UserAgreement',
  components: {},
  computed: {},
  data() {
    return {
      isGet: false,
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
h1 {
  text-align: center;
}

em {
  font-style: normal;
  font-weight: bold;
}
</style>
